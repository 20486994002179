












































































import { Order, Source } from "@/models";
import CreateModal from "@/components/WarehouseItem/Create.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import Search from "@/components/VEntrySearch.vue";

import EditModal from "@/components/WarehouseItem/Edit.vue";
import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import WarehouseItemsMixin from "@/mixins/http/WarehouseItemsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { WarehouseItem } from "@/models";
import EmptyView from "@/components/EmptyView.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_warehouse_items";
const destroyTag = "destroy_warehouse_item";

@Component({
  components: {
    EditModal,
    EmptyView,
    Search,
    VPageContentSticky,
    DataTable,
    CreateModal,
    ConfirmModal
  }
})
export default class WarehouseItemList extends Mixins(WarehouseItemsMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() createModal: HTMLDivElement;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  items: WarehouseItem[] = [];
  page = 1;

  columns = [
    { name: "name", label: "Nome" },
    { name: "sku", label: "Codice" },
    { name: "snapshot", label: "Quantità" },
    { name: "actions", label: "" }
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  onSelected(item: WarehouseItem) {
    this.$router.push({
      name: "warehouse_items_show",
      params: { id: item.id }
    });
  }

  onCreated(item: WarehouseItem) {
    this.items.unshift(item);
    this.onSelected(item);
  }

  onUpdated(item: WarehouseItem) {
    const index = this.items.findIndex(o => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getWarehouseItems(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: any) {
    this.page = 1;
    this.items = items.items;
  }

  onDeleted(id: string) {
    this.items = this.items.filter(o => id !== o.id);
  }

  openCreateModal() {
    (this.createModal as any).show();
  }

  openEditModal(item: WarehouseItem) {
    (this.editModal as any).show(item);
  }

  async openConfirmModal(item: WarehouseItem) {
    const content = "Sei sicuro di eliminare questo elemento di magazzino?"
    const confirmed = await (this.confirmModal as any).confirm(content);
     if(confirmed) {
      this.destroyItem(item.id);
     }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyWarehouseItem(id, destroyTag));
    if (errors) {
      showToast("Si è verificato un errore nell'eliminazione", { type: "error" });
      return;
    }

    this.onDeleted(id);
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      include: ["name"],
      page: this.page,
      filter: {
        company: sourceStore.currentSource?.company?.id,
        term: this.search
      }
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getWarehouseItems(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
