









































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import WarehouseItemsMixin from '@/mixins/http/WarehouseItemsMixin';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { Company } from '@/models';
import { namespace } from 'vuex-class';
import { WarehouseItem } from '@/models';
import { easync } from '@/utils/http';
import { showToast } from '@/utils/Toast';
import { sourceStore } from '@/store/typed';
const createRequestTag = 'create_request_tag';

@Component({
  components: {
    VModal
  }
})
export default class CreateModal extends Mixins(WarehouseItemsMixin) {
  @RegisterHttp(createRequestTag) createRequest: RequestData;

  modal!: VModal;
  content = '';

  private prototype: any = {
    name: null,
    sku: null,
    quantity: null
  };

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  loading() {
    return this.createRequest?.loading;
  }

  get valid() {
    return this.prototype.name && this.prototype.sku && this.prototype.quantity;
  }

  title() {
    return `Nuovo articolo del magazzino`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  async create() {
    const [data, errors] = await easync(this.createWarehouseItem({ ...this.prototype, companyId: sourceStore.company?.id }, createRequestTag));
    if (errors) {
      showToast("Si è verificato un errore in creazione", { type: "error" });
      return;
    }

    this.$emit('success', data);
    this.modal.hide();
    this.reset();
  }

  protected onConfirm() {
    this.create();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.prototype = {
      name: null,
      sku: null,
      quantity: null
    };
  }

  show() {
    this.modal.show();
  }
}
