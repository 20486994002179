


















































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import WarehouseItemsMixin from '@/mixins/http/WarehouseItemsMixin';

import { WarehouseItem } from '@/models';
import { RequestData } from '@/store/types/RequestStore';
import { RegisterHttp } from '@/utils/Decorators';
import { easync } from '@/utils/http';
import { showToast } from '@/utils/Toast';
const editRequestTag = 'edit_warehouse_item_request_tag';
const updateStockRequestTag = 'update_warehouse_item_stock_request_tag';

@Component({
  components: {
    VModal
  }
})
export default class CreateModal extends Mixins(WarehouseItemsMixin) {
  @RegisterHttp(editRequestTag) editRequest: RequestData;

  item: WarehouseItem| null = null;
  modal!: VModal;
  content = '';

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  get valid() {
    if (!this.item) {
      return false;
    }
    return this.item.name && this.item.sku && this.item.quantity;
  }

  title() {
    return `Aggiorna ${this.item.name}`;
  }

  async update() {
    const [data, errors] = await easync(this.updateWarehouseItem({ ...this.item }, editRequestTag));
    if (errors) {
      showToast("Si è verificato un errore in modifica", { type: "error" });
      return;
    }

    this.$emit('success', this.item);
    this.modal.hide();
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.update();
    }
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  show(item: WarehouseItem) {
    this.modal.show();
    this.item = { ...item } as WarehouseItem;
  }
}
